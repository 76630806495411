body {
    max-width: 700px;
    margin: 0 auto;
}

header {
    background: rgb(0, 45, 110);
    height: 100%;
    width: 100%;
    border-bottom: 1px solid rgb(181, 181, 185);
}

.date-day {
    font-size: 1.3rem;
}

.date-date {
    font-size: 2rem;
}

.hour-overview-row {

    padding: .3rem 1rem;
}

.bg-darkblue, .btn-darkblue {
    background: rgb(0, 45, 110);
}

.btn-outline-darkblue {
    border: 1px solid rgb(0, 45, 110);
    color: rgb(0, 45, 110);
}

.btn-outline-darkblue:active, .btn-outline-darkblue:hover,
.btn-outline-hover-darkblue:active, .btn-outline-hover-darkblue:hover {
    color: #eee;
    background: rgb(0, 45, 110);
}

a.dropdown-item:active {
    background: rgb(33, 136, 56);
}

.cursor-pointer {
    cursor: pointer;
}

.hide {
    display: none !important;
}
